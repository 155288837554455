export const PRIGRADSKE_VOZNJE = {
  AVDIBAŠIĆI: "35/38",
  BAKALUŠE: "18/22",
  BANOVIĆI: "40/45",
  BISTARAC: "17/22",
  BISTARACJEZERO: "20/25",
  BAŠIGOVCI: "30/35",
  BIKODŽE: "28/32",
  BREZE: "20/22",
  BRNJIK: "55/65",
  "BINGO ZOO VRT": "15/18",
  BREŠKE: "20/25",
  CAPARDE: "50/55",
  "CERIK (prema Kiseljaku)": "15/18",
  ČANIĆI: "30/35",
  CILJUGE: "28/32",
  ČAJIĆI: "15/18",
  ČEHAJE: "50/55",
  ČELIĆ: "55/65",
  DEVETAK: "30/35",
  DISKRECIJA: "15/18",
  DOBRINJA: "25/30",
  "DRAGUNJA DONJA": "35/38",
  "DRAGUNJA GORNJA": "40/45",
  DRIJENČA: "40/45",
  DUBNICA: "25/28",
  "DUBRAVE AERODROM": "30/35",
  "DUBRAVE DONJE": "17/19",
  "DUBRAVE GORNJE": "25/30",
  DOBOŠNICA: "35/40",
  "DOBOŠNICA GORNJA": "40/45",
  ĐURĐEVIK: "28/32",
  GALAXIS: "38/42",
  "GORNJA TUZLA CENTAR": "15/18",
  "GORNJA TUZLA N.NASELJE": "15/18",
  GNOJNICA: "40/45",
  GRAČANICA: "55/60",
  HASANBAŠIĆI: "15/18",
  "HIDANI (DOKANJ)": "15/18",
  "HRASNO DONJE": "25/30",
  "HRASNO GORNJE": "30/35",
  "HRGOVI DONJI": "50/55",
  HUMCI: "45/50",
  HUSINO: "13/15",
  "INTEREX ŠIĆKI": "15/18",
  JASICI: "12/15",
  JESENICE: "38/42",
  KALESIJA: "30/35",
  "KALESIJA GORNJA": "35/40",
  KARAKAJ: "70/75",
  KARANOVAC: "55/60",
  KIKAČI: "20/25",
  KISELJAK: "20/25",
  KOKSARA: "23/25",
  KOSCI: "15/20",
  KOVAČI: "23/28",
  "LIPNICA CENTAR": "17/20",
  "LIPNICA GORNJA": "20/25",
  "LIPNICA SREDNJA, DELIĆI": "17/20",
  LISOVIĆI: "35/40",
  LJEPUNICE: "20/25",
  LOHINJA: "45/50",
  LOPARE: "45/50",
  LUKAVAC: "20/25",
  "LUKAVAC TURSKI": "25/30",
  LJUBAČE: "18/20",
  MAHALA: "40/45",
  "MAJEVICA Nova (Jojina kafana vikendice)": "30/35",
  "MAJEVICA Stara (Vikendice,Ćuma- bungalovi,Lovački dom)": "30/35",
  "MEĐAŠ DOM": "20/25",
  "MALINE (do semafora Živinice)": "18/20",
  "MEĐAŠ RASKRŠČE": "18/23",
  MEMIĆI: "35/40",
  "MIHATOVIĆI (domovina)": "17/20",
  "MIHATOVIĆI (izbjegličko naselje)": "20/22",
  MILJANOVCI: "23/25",
  MIRIČINA: "35/40",
  "MRAMOR (centar)": "22/25",
  "MRAMOR (stari)": "25/28",
  NAHVIOCI: "45/50",
  NIPEX: "20/25",
  "OBODNICA DONJA/GORNJA": "25/28",
  ORAHOVICA: "40/45",
  "ORAHOVICA GORNJA": "45/50",
  ORMANICA: "50/55",
  OSKOVA: "33/38",
  OSMACI: "50/55",
  OSOJE: "30/35",
  "PAR SELO (poslije Timuna)": "15/18",
  PASCI: "20/25",
  PETROVICE: "17/19",
  PLANE: "15/18",
  "PLAŽA (MODRAC)": "25/30",
  PODORAŠJE: "30/35",
  PODPEĆ: "38/42",
  PODŠIĆI: "15/18",
  POLJICE: "30/35",
  PREVILE: "33/35",
  PRIBAVA: "45/50",
  PRIBOJ: "40/45",
  PRILUK: "25/30",
  PROKOSOVIĆI: "25/30",
  PURAČIĆ: "25/30",
  RATKOVIĆI: "60/65",
  RAINCI: "25/30",
  RIBNICA: "65/70",
  SAPNA: "55/60",
  SARAČI: "22/25",
  SELJUBLJE: "25/30",
  SIŽJE: "35/40",
  "SNIJEŽNICA (pr. Priboja)": "45/50",
  SREBRENIK: "50/55",
  ŠERIĆI: "25/30",
  STUPARI: "40/45",
  SUHA: "20/25",
  "SVOJTINA (od crkve lijevo)": "15/20",
  ŠEVAR: "18/20",
  ŠIBOŠNICA: "40/45",
  "ŠIĆKI BROD (Guma M,petlja Šićki)": "12/15",
  ŠIKULJE: "30/35",
  ŠPIONICA: "45/50",
  TEOČAK: "50/55",
  TETIMA: "15/20",
  TINJA: "35/40",
  TRSTJE: "25/30",
  TURIJA: "30/35",
  TOJŠIĆI: "15/18",
  RAPAČE: "12/15",
  VIŠĆA: "30/35",
  VUKOVIJE: "27/32",
  VRAŽIĆI: "60/65",
  ZUKIĆI: "40/45",
  ŽIVINICE: "20/25",
  "ZELENI KAMEN": "25/30",
  ZLAĆA: "40/45",
};

export const MEDJUGRADSKE_VOZNJE = {
  ARIZONA: 80,
  BABANOVAC: 275,
  "BANJA LUKA": 245,
  "BANJA VRUĆICA": 135,
  "BEGOV HAN": 170,
  BJELAŠNICA: 225,
  BIHAĆ: 460,
  BIJELJINA: 105,
  BILEĆA: 430,
  "BOSANSKI BROD": 180,
  "BOSANSKA KRUPA": 400,
  "BOSANSKA GRADIŠKA": 270,
  "BOSANSKI NOVI": 350,
  "BOSANSKI ŠAMAC": 120,
  BRATUNAC: 140,
  BRČKO: 100,
  "BRIJESNICA MALA": 70,
  BRKA: 95,
  "BRIJESNICA VELIKA": 75,
  BUGOJNO: 300,
  BUNA: 350,
  BUSOVAČA: 240,
  CAZIN: 425,
  DERVENTA: 145,
  ČAPLJINA: 390,
  ČITLUK: 370,
  DOBOJ: 90,
  DOLJANI: 400,
  DOMALJEVAC: 130,
  "DONJI VAKUF": 300,
  DRINJAČA: 105,
  DVOROVI: 120,
  FOČA: 290,
  FOJNICA: 240,
  GODUŠ: 75,
  GORAŽDE: 240,
  GRADAČAC: 90,
  GRUDE: 400,
  HADŽIĆI: 185,
  "HAN PIJESAK": 140,
  ILIJAŠ: 165,
  JABLANICA: 270,
  JAHORINA: 225,
  JAJCE: 350,
  JAKEŠ: 120,
  JANJA: 110,
  JELAH: 115,
  KAKANJ: 220,
  "KARUŠE (HIFA)": 95,
  KEREP: 80,
  Kladanj: 75,
  KLJUČ: 345,
  KLOKOTNICA: 75,
  "KONJEVIĆ POLJE": 110,
  KONJIC: 235,
  "KOTOR VAROŠ": 205,
  KOTORSKO: 110,
  KOZLUK: 90,
  "KULAŠI BANJA": 140,
  LAKTAŠI: 215,
  LAŠVA: 215,
  LIVNO: 395,
  LONČARI: 100,
  "LUKAVAC G. (GRADAČAC)": 80,
  LJUBINJE: 420,
  LJUBUŠKI: 400,
  MAGLAJ: 130,
  MATUZIĆI: 100,
  MEĐUGORJE: 380,
  MILIĆI: 130,
  MODRIČA: 115,
  MOSTAR: 340,
  "MRKONJIĆ GRAD": 330,
  NEMILA: 170,
  NEUM: 460,
  NEVESINJE: 400,
  ODŽAK: 125,
  OLOVO: 100,
  ORAŠJE: 120,
  "PAVLOVIĆA MOST": 125,
  POSUŠJE: 380,
  PRIJEDOR: 310,
  PRNJAVOR: 160,
  RAČA: 150,
  RAHIĆ: 100,
  ROGATICA: 210,
  "SANSKI MOST": 350,
  "SARAJEVO (pr. Olova)": 170,
  "SARAJEVO (pr. Zenice)": 295,
  SEMIZOVAC: 160,
  "SNIJEŽNICA (pr. Sapne)": 90,
  SOKOLAC: 180,
  SREBRENICA: 160,
  SREDNJE: 140,
  STANARI: 130,
  "STJEPAN POLJE": 70,
  STOLAC: 390,
  "STANIĆ RIJEKA": 85,
  "SUHO POLJE": 85,
  ŠEKOVIĆI: 90,
  ŠEPAK: 100,
  ŠIJE: 110,
  "ŠIROKI BRIJEG": 370,
  TESLIĆ: 130,
  TEŠANJ: 125,
  TEŠANJKA: 105,
  TRAVNIK: 250,
  TREBINJE: 480,
  TURBE: 260,
  UGLJEVIK: 75,
  "VELIKA KLADUŠA": 470,
  "VISOKO (pr. Semizovca)": 170,
  "VISOKO (pr. Zenice)": 260,
  VIŠEGRAD: 240,
  VITEZ: 215,
  VITINICA: 65,
  VLASENICA: 110,
  VLAŠIĆ: 280,
  VOGOŠĆA: 160,
  VOZUĆA: 80,
  VUČKOVCI: 75,
  ZAVIDOVIĆI: 115,
  ZENICA: 190,
  "ZOVIK (donji)": 80,
  "ZOVIK (gornji)": 80,
  ZVORNIK: 80,
  "ŽEPČE (pr. Zavidovića)": 130,
  ŽITOMISLIĆI: 365,
};

export const DALJINSKE_VOZNJE = {
  Hrvatska: {
    "BAŠKE VODE": 440,
    "BIOGRAD (na moru)": 670,
    "BIZOVAČKE TOPLICE": 250,
    BRELA: 450,
    BREGANA: 500,
    CAVTAT: 560,
    DRVENIK: 480,
    DUBROVNIK: 560,
    GRADAC: 460,
    KARLOVAC: 540,
    LABIN: 790,
    MAKARSKA: 490,
    NAŠICE: 235,
    "NOVA GRADIŠKA": 280,
    OMIŠ: 480,
    OPATIJA: 720,
    OREBIĆ: 510,
    OSIJEK: 230,
    PODGORA: 460,
    PULA: 860,
    RIJEKA: 700,
    SLANO: 540,
    "SLAVONSKI BROD": 190,
    SPLIT: 550,
    ŠIBENIK: 590,
    TROGIR: 570,
    TUČEPI: 460,
    VARAŽDIN: 550,
    VINKOVCI: 160,
    VIROVITICA: 350,
    VODICE: 600,
    ZADAR: 680,
    ZAGREB: 460,
    ZAOSTROG: 470,
    ŽUPANJA: 125,
  },
  Srbija: {
    "BAČKA PALANKA preko Iloka": 210,
    "BAČKA PALANKA preko N.S": 300,
    "BANJA KOVILJAČA": 105,
    BEČEJ: 340,
    "BEOGRAD (aerodrom/grad)": "270/300",
    ČAČAK: 330,
    IRIG: 240,
    KIKINDA: 420,
    KOVIN: 385,
    KRAGUJEVAC: 510,
    KRALJEVO: 380,
    KRUŠEVAC: 590,
    LESKOVAC: 720,
    LOZNICA: 110,
    NIŠ: 660,
    "NOVI SAD": 280,
    "NOVI PAZAR": 490,
    PANČEVO: 340,
    PIROT: 760,
    POŽAREVAC: 420,
    RUMA: 230,
    SENTA: 400,
    SJENICA: 410,
    SMEDEREVO: 400,
    SOMBOR: 290,
    SUBOTICA: 430,
    "ŠID – ŠABAC": 190,
    UŽICE: 250,
    VALJEVO: 220,
    VRANJE: 820,
    VRŠAC: 430,
    ZLATIBOR: 310,
    ZRENJANIN: 340,
  },
  "Crna Gora": {
    BAR: 590,
    BUDVA: 600,
    "HERCEG NOVI": 550,
    KOTOR: 590,
    NIKŠIĆ: 450,
    "PETROVAC NA MORU": 600,
    PODGORICA: 520,
    TIVAT: 590,
    ULCINJ: 630,
  },
  Kosovo: {
    GNJILANE: 960,
    "KOSOVSKA MITROVICA": 700,
    PRIŠTINA: 730,
    PRIZREN: 760,
    UROŠEVAC: 770,
  },
  Makedonija: {
    GOSTIVAR: 1060,
    KUMANOVO: 900,
    SKOPLJE: 960,
    STRUMICA: 1100,
    ŠTIP: 1000,
    TETOVO: 1020,
    VELES: 1470,
  },
  Slovenija: {
    LJUBLJANA: 670,
  },
  Mađarska: {
    HARKANJ: 330,
    PEČUH: 360,
    BUDIMPEŠTA: 630,
  },
};

export const GRADSKE_VOZNJE = {};
