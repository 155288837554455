import React, { createContext, useState, useEffect } from "react";

const MyContext = createContext();

const MyContextProvider = ({ children }) => {
  const [price, setPrice] = useState("");
  const [language, setLanguage] = useState(
    JSON.parse(localStorage.getItem("lang")) || "Bosnian"
  );
  const [languageData, setLanguageData] = useState(null);
  const [destination, setDestination] = useState("");
  const [finalDestination, setFinalDestination] = useState("");

  useEffect(() => {
    const fetchLanguage = async (lang) => {
      try {
        const response = await fetch(lang);
        const jsonData = await response.json();
        setLanguageData(jsonData);
      } catch (error) {
        console.error("Error loading JSON file:", error);
      }
    };

    if (language === "Bosnian") {
      fetchLanguage("BA.json");
    }

    if (language === "English") {
      fetchLanguage("US.json");
    }
  }, [language]);

  return (
    <MyContext.Provider
      value={{
        language,
        setLanguage,
        languageData,
        price,
        setPrice,
        destination,
        setDestination,
        finalDestination,
        setFinalDestination,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export { MyContext, MyContextProvider };
