import React from "react";
import Navbar from "./Navbar";
import Calculator from "./Calculator";
import { useContext } from "react";
import { MyContext } from "../context/context";
import Contact from "./Contact";
import Footer from "./Footer";

const Pricing = () => {
  const { price, languageData, finalDestination } = useContext(MyContext);

  return (
    <section className="text-[0.9rem] md:text-[1rem]">
      <Navbar onPricingPage={true} />

      <div className="max-w-6xl mx-auto">
        <div className="grid items-center md:grid-cols-2">
          <div className="bg-yellow-400 md:h-max">
            <Calculator />
          </div>
          <div className="flex flex-col space-y-1 items-center justify-center h-[5rem] text-black bg-yellow-400 md:h-full">
            <p className="text-2xl font-bold">{finalDestination}</p>
            <p className="text-2xl font-bold">
              {languageData?.pricingPriceSpan}: {price} KM
            </p>
          </div>
        </div>

        <p className="mt-20 mb-2 text-2xl font-bold text-center">
          {languageData?.pricingTitleOne}
        </p>

        <div className="">
          {Object.entries(languageData?.cityRides || {}).map(([key, value]) => (
            <div key={key} className="">
              <h3 className="p-2 px-2 font-bold text-white bg-gray-900">
                {key}
              </h3>
              <div>
                {value.map((sentence) => (
                  <p
                    key={sentence}
                    className="p-1 font-bold border-b border-black border-x"
                  >
                    {sentence}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-20 font-bold">
          <p className="mb-2 text-2xl font-bold text-center">
            {languageData?.pricingTitleTwo}
          </p>
          {Object.entries(languageData?.cityRidesShort || {}).map(
            ([key, value]) => (
              <div key={key} className="">
                <p className="p-2 font-bold bg-yellow-400 border border-black">
                  {key}
                </p>
                <p className="p-2 border-black">{value}</p>
              </div>
            )
          )}
        </div>

        <div className="mt-20 font-bold">
          <p className="mb-2 text-2xl font-bold text-center">
            {languageData?.pricingTitleThree}
          </p>
          {Object.entries(languageData?.cityRidesLong || {}).map(
            ([key, value]) => (
              <div key={key} className="">
                <p className="p-2 font-bold text-white bg-gray-900 border border-black">
                  {key}
                </p>
                <p className="p-2 border border-black">{value}</p>
              </div>
            )
          )}
        </div>

        <div className="mt-20 font-bold">
          <p className="mb-2 text-2xl font-bold text-center">
            {languageData?.pricingTitleFour}
          </p>
          {Object.entries(languageData?.cityRidesDeliveries || {}).map(
            ([key, value]) => (
              <div key={key} className="">
                <p className="p-2 font-bold text-black bg-yellow-400 border border-black">
                  {key}
                </p>
                <p className="p-2">{value}</p>
              </div>
            )
          )}
        </div>

        <div className="mt-20 font-bold">
          <p className="mb-2 text-2xl font-bold text-center">
            {languageData?.pricingTitleFive}
          </p>
          {Object.entries(languageData?.suburbanRides || {}).map(
            ([key, value]) => (
              <div key={key} className="">
                <p className="p-2 font-bold text-white bg-gray-900 border border-black">
                  {key}
                </p>
                <p className="p-2 border border-black">{value}</p>
              </div>
            )
          )}
        </div>

        <div className="mt-20 font-bold">
          <p className="mb-2 text-2xl font-bold text-center">
            {languageData?.pricingTitleSix}
          </p>
          {Object.entries(languageData?.longDistanceRides || {}).map(
            ([key, value]) => (
              <div key={key} className="">
                <p className="p-2 font-bold text-black bg-yellow-400 border border-black">
                  {key}
                </p>
                <p className="p-2">{value}</p>
              </div>
            )
          )}
        </div>
      </div>

      <div className="mt-20">
        <Contact />
        <Footer />
      </div>
    </section>
  );
};

export default Pricing;
