import { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";

const GoToTopBtn = () => {
  const [isVisible, setIsVisible] = useState(false);

  const goTopHandler = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const listenToScroll = () => {
      let height = 100;
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      if (winScroll > height) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", listenToScroll);
  }, [setIsVisible]);

  return (
    <div className="fixed bottom-2 right-2">
      {isVisible && (
        <button
          className="bg-yellow-400 rounded-full border-2 border-black h-[3rem] w-[3rem] flex items-center justify-center hover:bg-gray-900 hover:border-yellow-400 hover:text-white duration-300"
          onClick={goTopHandler}
        >
          <FaArrowUp size={25} className="" />
        </button>
      )}
    </div>
  );
};

export default GoToTopBtn;
