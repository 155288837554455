import React from "react";
import { useContext } from "react";
import { MyContext } from "../context/context";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";

const About = () => {
  const { languageData } = useContext(MyContext);

  const {
    ref: imageRef,
    inView: imageVisible,
    entry,
  } = useInView({
    triggerOnce: true,
  });

  const {
    ref: textRef,
    inView: TextVisible,
    textEntry,
  } = useInView({
    triggerOnce: true,
  });

  return (
    <section
      id="about"
      className="grid max-w-6xl md:my-20 text-black md:mx-4 lg:mx-auto lg:space-y-0 lg:grid-cols-2"
    >
      <div
        id="aboutContainer"
        className="flex items-center justify-center bg-black h-[30rem]"
      >
        <img
          ref={imageRef}
          id="aboutImage"
          src="/images/logo.png"
          alt=""
          className={classNames(
            "w-[10rem] h-[10rem] md:w-[20rem] md:h-[20rem] opacity-0",
            {
              "fade-in": imageVisible,
            }
          )}
        ></img>
      </div>

      <div className="p-4 bg-yellow-400 md:px-4">
        <div
          ref={textRef}
          className={classNames("opacity-0", {
            "fade-in": TextVisible,
          })}
        >
          <h3 className="p-2 text-2xl md:text-4xl font-bold text-white w-max">
            {languageData?.aboutTitle}
          </h3>

          <p className="p-2 text-[0.9rem] md:text-[1rem] leading-6 md:leading-[1.65rem] text-black">
            {languageData?.aboutText}
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
