import React, { useRef, useState, useCallback } from "react";
import { useContext } from "react";
import { MyContext } from "../context/context";
import axios from "axios";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ContactInfo from "./ContactInfo";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";

const Contact = () => {
  const nameRef = useRef();
  const emailRef = useRef();
  const telephoneRef = useRef();
  const messageRef = useRef();
  const [message, setMessage] = useState("");
  const [openSuccesModal, setOpenSuccessModal] = useState(false);
  const { languageData } = useContext(MyContext);
  const startRef = useRef();
  const endRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    ref: myRef,
    inView: myElementVisible,
    entry,
  } = useInView({
    triggerOnce: true,
  });

  const handleSubmitForm = useCallback(
    (e) => {
      e.preventDefault();

      if (
        !nameRef.current.value ||
        !emailRef.current.value ||
        !telephoneRef.current.value ||
        !startRef.current.value ||
        !endRef.current.value
      ) {
        setMessage(languageData?.formReject);
        return;
      }

      if (!executeRecaptcha) {
        return;
      }
      executeRecaptcha("submit").then((gReCaptchaToken) => {
        submitForm(gReCaptchaToken);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [executeRecaptcha]
  );

  const submitForm = async (token) => {
    setMessage("");

    try {
      setIsLoading(true);
      await axios.post(
        process.env.REACT_APP_CONTACT_HOST,
        JSON.stringify({
          name: nameRef.current.value,
          email: emailRef.current.value,
          message: messageRef.current.value,
          phone: telephoneRef.current.value,
          start: startRef.current.value,
          end: endRef.current.value,
          token: token,
        })
      );

      setError(false);
      setOpenSuccessModal(true);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setIsLoading(false);
      setOpenSuccessModal(true);
    }

    nameRef.current.value = "";
    emailRef.current.value = "";
    messageRef.current.value = "";
    telephoneRef.current.value = "";
    startRef.current.value = "";
    endRef.current.value = "";
  };

  return (
    <section id="contact" className={classNames("grid lg:grid-cols-2 ", {})}>
      <ContactInfo />

      <div
        ref={myRef}
        className="flex flex-col items-center justify-center px-5 py-5 bg-gray-900"
      >
        <h4 className="text-2xl md:text-3xl font-bold text-center text-white">
          {languageData?.contactTitle}
        </h4>

        <form
          onSubmit={handleSubmitForm}
          className={classNames(
            "flex flex-col w-full mt-10 space-y-3 text-[0.9rem] md:text-[1rem] text-white opacity-0",
            {
              "fade-in": myElementVisible,
            }
          )}
        >
          <input
            ref={nameRef}
            className="p-2 py-2 lg:py-3 bg-transparent border focus:outline-none focus:border-yellow-500 placeholder:text-white lg:w-[80%]"
            placeholder={languageData?.contactName}
          />

          <input
            type="email"
            ref={emailRef}
            className="p-2 py-2 lg:py-3 bg-transparent border focus:outline-none focus:border-yellow-500 placeholder:text-white  lg:w-[80%]"
            placeholder={languageData?.contactEmail}
          />

          <input
            type="number"
            ref={telephoneRef}
            className="p-2 py-2 lg:py-3 bg-transparent border focus:outline-none focus:border-yellow-500 placeholder:text-white lg:w-[80%]"
            placeholder={languageData?.contactPhone}
          />

          <input
            ref={startRef}
            className="p-2 py-2 lg:py-3 bg-transparent border focus:outline-none focus:border-yellow-500 placeholder:text-white lg:w-[80%]"
            placeholder={languageData?.contactStart}
          />

          <input
            ref={endRef}
            className="p-2 py-2 lg:py-3 bg-transparent border focus:outline-none focus:border-yellow-500 placeholder:text-white lg:w-[80%]"
            placeholder={languageData?.contactEnd}
          />

          <textarea
            ref={messageRef}
            rows={3}
            className="px-2 pt-1 bg-transparent border focus:outline-none focus:border-yellow-500 placeholder:text-white  lg:w-[80%]"
            placeholder={languageData?.contactMessage}
          />

          {message && (
            <p className="text-yellow-400 text-center md:text-left">
              {message}
            </p>
          )}

          <div className="flex flex-col space-y-1">
            <button
              type="submit"
              className="text-[0.9rem] md:text-[1rem] mx-auto md:mx-0 py-3 px-7 font-bold rounded-full text-gray-800 duration-300 bg-yellow-400 border border-black  w-max hover:bg-gray-900 hover:border-yellow-400 hover:text-white"
            >
              {languageData?.contactButton}
            </button>

            <p className="py-2 text-center md:text-left text-[0.7rem]">
              This site is protected by reCAPTCHA and the Google{" "}
              <a
                className="text-yellow-400"
                href="https://policies.google.com/privacy"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                className="text-yellow-400"
                href="https://policies.google.com/terms"
              >
                Terms of Service
              </a>{" "}
              apply.
            </p>
          </div>
        </form>
      </div>

      {isLoading && (
        <div className="flex items-center justify-center fixed top-0 bottom-0 left-0 right-0 bg-[rgb(0,0,0,0.5)] z-20">
          <div className="flex flex-col items-center justify-center p-5 space-y-5 bg-white rounded-md">
            <p>{languageData?.formPending}</p>

            <div className="loader"></div>
          </div>
        </div>
      )}

      {openSuccesModal && languageData && (
        <div className="flex items-center justify-center fixed top-0 bottom-0 left-0 right-0 bg-[rgb(0,0,0,0.5)] z-20">
          <div className="flex flex-col items-center bg-white py-5 rounded-md mx-2 px-3 md:w-[30rem]">
            <p className="py-5 text-center">
              {error && languageData
                ? languageData.formError
                : languageData.formSuccess}
            </p>

            <button
              onClick={() => {
                setError(false);
                setOpenSuccessModal(false);
              }}
              className="px-5 py-2 font-bold text-gray-800 duration-300 bg-yellow-400 border border-black w-max hover:bg-gray-900 hover:border-yellow-400 hover:text-white"
            >
              Nastavi
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Contact;
