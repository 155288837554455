import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../context/context";
import { Link } from "react-router-dom";

const NavbarListItems = ({ scrollToPage, onPricingPage }) => {
  const { languageData } = useContext(MyContext);
  const navigate = useNavigate();

  return (
    <div className="hidden font-bold md:flex space-x-7">
      <span
        onClick={() => {
          if (!onPricingPage) {
            scrollToPage("hero");
          } else {
            navigate("/");
          }
        }}
        className="cursor-pointer hover:text-yellow-500"
      >
        {languageData?.navFirstItem}
      </span>
      {!onPricingPage && (
        <span
          onClick={() => scrollToPage("about")}
          className="cursor-pointer hover:text-yellow-500"
        >
          {languageData?.navSecondItem}
        </span>
      )}
      {!onPricingPage && (
        <Link to="/cjenovnik">
          <span className="cursor-pointer hover:text-yellow-500">
            {languageData?.navThirdItem}
          </span>
        </Link>
      )}
      <span
        onClick={() => {
          scrollToPage("contact");
        }}
        className="cursor-pointer hover:text-yellow-500"
      >
        {languageData?.navFourthItem}
      </span>
    </div>
  );
};

export default NavbarListItems;
