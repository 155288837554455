import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import MobileNav from "./MobileNav";
import { Link } from "react-router-dom";
import { GrFormClose } from "react-icons/gr";
import LanguageSelector from "./LanguageSelector";
import ContactBar from "./ContactBar";
import NavbarListItems from "./NavbarListItems";

const Navbar = ({ onPricingPage }) => {
  const [open, setOpen] = useState(false);

  const scrollToPage = (id) => {
    const page = document.getElementById(id);
    page.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="shadow-md md:bg-white  w-full text-[0.9rem] md:text-[1rem]">
      <ContactBar />

      <div className="flex items-center justify-between max-w-6xl py-2 mx-5 lg:px-0 lg:mx-auto">
        <Link to="/">
          <img
            src="/images/logo.png"
            alt=""
            className="w-[3rem] h-[3rem] md:h-[4.6rem] md:w-[4.5rem]"
          />
        </Link>

        <div className="flex items-center">
          <div className="md:hidden">
            <LanguageSelector onPricingPage={onPricingPage} />
          </div>

          <button
            className="md:hidden"
            onClick={() => setOpen((prev) => !prev)}
          >
            {!open ? <GiHamburgerMenu size={25} /> : <GrFormClose size={35} />}
          </button>
        </div>

        <NavbarListItems
          scrollToPage={scrollToPage}
          onPricingPage={onPricingPage}
        />
      </div>

      <div className="relative">
        {open && (
          <MobileNav
            scrollToPage={scrollToPage}
            onPricingPage={onPricingPage}
          />
        )}
      </div>
    </div>
  );
};

export default Navbar;
