import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { MyContext } from "../context/context";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import classnames from "classnames";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";

// const slides = ["/images/hero.jpg", "/images/hero.jpg", "/images/hero3.jpg"];

const slides = [
  { image: "/images/slika1.jpg" },
  { image: "/images/slika2.jpg" },
  { image: "/images/slika3.jpg" },
  { image: "/images/slika4.jpg" },
  { image: "/images/slika5.jpg" },
  { image: "/images/slika6.jpg" },
  { image: "/images/slika7.jpg" },
  { image: "/images/slika8.jpg" },
  { image: "/images/slika9.jpg" },
  { image: "/images/slika10.jpg" },
  { image: "/images/slika11.jpg" },
];

const Hero = () => {
  const [hover, setHover] = useState(false);
  const { languageData } = useContext(MyContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    ref: myRef,
    inView: myElementVisible,
    entry,
  } = useInView({
    triggerOnce: true,
  });

  const scrollToPage = (id) => {
    const page = document.getElementById(id);
    page.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    let interval;

    if (!hover) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
      }, 2000); // Change slide every 3 seconds
    }

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, [hover]);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <section
      id="hero"
      className="grid overflow-x-hidden lg:grid-cols-3 slide-container"
    >
      <div
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="relative flex items-center justify-center h-[15rem] md:h-[30rem] lg:h-full lg:col-span-2 group bg-black"
      >
        <img
          alt=""
          src={slides[currentIndex].image}
          className="object-fill h-[15rem] md:h-[30rem] lg:h-[40rem]"
        />

        <div className="hidden bg-white group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <BsChevronCompactLeft
            className="text-black"
            onClick={prevSlide}
            size={25}
          />
        </div>

        <div className="hidden bg-white group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <BsChevronCompactRight
            className="text-black"
            onClick={nextSlide}
            size={25}
          />
        </div>
      </div>

      <div
        className={classNames(
          "flex items-center lg:bg-gray-900 lg:h-[48rem] text-black lg:text-white px-5"
        )}
      >
        <div
          ref={myRef}
          className={classnames("py-5 md:py-10 opacity-0", {
            "fade-in": myElementVisible,
          })}
        >
          <span className="text-yellow-400 font-bold">Tuzla Taxi</span>

          <h2 className="text-2xl font-bold md:text-4xl">
            {languageData?.homeTitle}
          </h2>

          <p className="my-4 md:my-5 text-[0.9rem] md:text-[1rem] leading-6 md:leading-7">
            {languageData?.homeText}
          </p>

          <button
            onClick={() => scrollToPage("contact")}
            className="p-2 py-3 text-[0.9rem] md:text-[1rem] font-bold text-gray-800 duration-300 bg-yellow-400 border w-[10rem] lg:border-black lg:p-3 hover:bg-gray-900 lg:hover:border-yellow-400 rounded-full hover:text-white"
          >
            {languageData?.homeButtonText}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
