import React from "react";
import { useContext, useRef, useEffect } from "react";
import { MyContext } from "../context/context";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import classNames from "classnames";

const LanguageModal = ({ setOpenTranslations }) => {
  const modalRef = useRef();
  const { language, setLanguage } = useContext(MyContext);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenTranslations(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="absolute top-10 right-[-2rem] shadow-[0_3px_10px_rgb(0,0,0,0.15)] bg-white rounded-xl p-2 w-[9rem]">
      <div ref={modalRef}>
        <button
          onClick={() => {
            setLanguage("Bosnian");
            localStorage.setItem("lang", JSON.stringify("Bosnian"));
          }}
          className={classNames(
            "flex space-x-2 hover:bg-gray-200 pl-3 py-2 rounded-xl w-full",
            {
              "text-black font-bold": language === "Bosnian",
            }
          )}
        >
          <span>{getUnicodeFlagIcon("BA")}</span>
          <span>BiH</span>
        </button>

        <button
          onClick={() => {
            setLanguage("English");
            localStorage.setItem("lang", JSON.stringify("English"));
          }}
          className={classNames(
            "flex space-x-2 hover:bg-gray-200 py-2 rounded-xl pl-3 w-full",
            {
              "text-black font-bold": language === "English",
            }
          )}
        >
          <span>{getUnicodeFlagIcon("GB")}</span>
          <span>EN</span>
        </button>
      </div>
    </div>
  );
};

export default LanguageModal;
