import React from "react";
import { useContext } from "react";
import { MyContext } from "../context/context";
import { Link, useNavigate } from "react-router-dom";

const MobileNav = ({ scrollToPage, onPricingPage }) => {
  const { languageData } = useContext(MyContext);
  const navigate = useNavigate();

  return (
    <div className="absolute z-10 flex justify-center w-full text-black bg-white border-t text-center">
      <div className="flex flex-col space-y-2 text-2xl py-7">
        <span
          onClick={() => {
            if (!onPricingPage) {
              scrollToPage("hero");
            } else {
              navigate("/");
            }
          }}
          className="cursor-pointer hover:text-yellow-500"
        >
          {languageData?.navFirstItem}
        </span>
        {!onPricingPage && (
          <span
            onClick={() => scrollToPage("about")}
            className="cursor-pointer hover:text-yellow-500"
          >
            {languageData?.navSecondItem}
          </span>
        )}
        <Link to="/cjenovnik">
          <span className="cursor-pointer hover:text-yellow-300">
            {languageData?.navThirdItem}
          </span>
        </Link>
        <span
          onClick={() => scrollToPage("contact")}
          className="cursor-pointer hover:text-yellow-500"
        >
          {languageData?.navFourthItem}
        </span>
      </div>
    </div>
  );
};

export default MobileNav;
