import React from "react";
import { useContext } from "react";
import { MyContext } from "../context/context";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";

const ContactInfo = () => {
  const { languageData } = useContext(MyContext);
  const {
    ref: myRef,
    inView: myElementVisible,
    entry,
  } = useInView({
    triggerOnce: true,
  });

  return (
    <div
      ref={myRef}
      className={classNames(
        "relative flex items-center justify-center bg-yellow-400",
        {}
      )}
    >
      <div
        className={classNames(
          "flex flex-col items-center justify-between py-20 space-y-5 opacity-0",
          {
            "fade-in": myElementVisible,
          }
        )}
      >
        <div className="flex space-x-4">
          <a
            href="https://wa.me/38762979333"
            className="flex flex-col items-center text-white hover:text-green-400"
          >
            <img
              src="/images/whatsUp.png"
              alt=""
              className="h-[2.5rem] w-[2.5rem] md:w-[3rem] md:h-[3rem]"
            />
            <span className="">WhatsApp</span>
          </a>

          <a
            href="viber://chat/?number=%2B38762979333"
            className="flex flex-col items-center text-white hover:text-purple-400"
          >
            <img
              src="/images/viber.png"
              alt=""
              className="w-[2.5rem] h-[2.5rem] md:w-[3rem] md:h-[3rem]"
            />
            <span className="">Viber</span>
          </a>

          <a
            className="flex flex-col items-center space-y-2 text-white hover:text-blue-500"
            href="https://www.facebook.com/tuzlataxi1517/"
          >
            <img
              src="images/face.png"
              alt=""
              className="h-[2rem] w-[2.5rem] md:w-[3rem] md:h-[2.5rem]"
            />
            <span className="">Facebook</span>
          </a>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-2xl font-bold text-white">
            {languageData?.contactTitleOne}
          </p>
          <a
            className="text-[1.1rem] md:text-xl font-bold text-black underline hover:text-white"
            href="mailto:narucitetaxi@tuzlataxi.com"
          >
            narucitetaxi@tuzlataxi.com
          </a>
        </div>

        <div className="flex flex-col items-center space-y-2">
          <p className="text-2xl font-bold text-white">
            {" "}
            {languageData?.contactTitleTwo}
          </p>

          <ul className="font-bold list-disc w-max">
            <li className="text-[1.1rem] md:text-xl underline hover:text-white">
              <a href="tel:+38735360725">035 360-725</a>
            </li>
            <li className="text-[1.1rem] md:text-xl underline hover:text-white">
              <a href="tel:+38735360727">035 360-727</a>
            </li>
            <li className="text-[1.1rem] md:text-xl underline hover:text-white">
              <a href="tel:+38735360726">035 360-726</a>
            </li>
            <li className="text-[1.1rem] md:text-xl underline hover:text-white">
              <a href="tel:+38762979333">062 979-333</a>
            </li>
          </ul>
        </div>

        <div className="flex flex-col items-center space-y-1 font-bold">
          <p className="text-2xl font-bold text-white">
            {languageData?.contactTitleThree}
          </p>

          <p className="text-[1.1rem] md:text-xl">Almir Kovačević</p>

          <a
            className="text-[1.1rem] md:text-xl text-black underline hover:text-white"
            href="mailto:predsjednik@tuzlataxi.com"
          >
            predsjednik@tuzlataxi.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
