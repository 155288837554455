import React from "react";
import { useContext } from "react";
import { MyContext } from "../context/context";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";

const MobileApp = () => {
  const { languageData } = useContext(MyContext);
  const {
    ref: myRef,
    inView: myElementVisible,
    entry,
  } = useInView({
    triggerOnce: true,
  });

  return (
    <section
      ref={myRef}
      id="about"
      className="grid max-w-6xl my-10 md:gap-20 text-black lg:mb-20 lg:mx-auto lg:space-y-0 lg:grid-cols-2"
    >
      <div
        className={classNames("px-4 lg:px-0 h-max opacity-0", {
          "fade-in": myElementVisible,
        })}
      >
        <p className="text-yellow-500 lg:text-left text-center">Tuzla Taxi</p>
        <h2 className="text-2xl md:leading-[2.5rem] md:text-[2.5rem] text-center lg:text-left font-bold text-black">
          {languageData?.appTitle}
        </h2>

        <img
          src="/images/mobile.png"
          alt=""
          className={classNames("lg:hidden", {
            "animate-left": myElementVisible,
          })}
        />

        <p className="my-3 md:my-8 text-[0.9rem] md:text-[1rem] leading-7 text-center lg:text-left text-gray-700">
          {languageData?.appText}
        </p>

        <div className="flex items-center justify-center md:flex-col lg:items-start">
          <a
            href="https://play.google.com/store/apps/details?id=ba.boss.taxi"
            className="px-7 text-[0.9rem] md:text-[1rem] py-3 font-bold text-gray-800 rounded-full duration-300 bg-yellow-400 cursor-pointer hover: hover:bg-gray-900 hover:text-white"
          >
            {languageData?.appButton}
          </a>
        </div>

        <img src="images/qr.png" className="mt-8  hidden lg:block" />
      </div>

      <div
        className={classNames({
          "animate-right": myElementVisible,
        })}
      >
        <img
          id="phone"
          src="/images/mobile.png"
          alt=""
          className="hidden md:block"
        />
      </div>
    </section>
  );
};

export default MobileApp;
