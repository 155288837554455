import React, { useState } from "react";
import { useContext } from "react";
import { MyContext } from "../context/context";
import LanguageModal from "./LanguageModal";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

const LanguageSelector = () => {
  const [openTranslations, setOpenTranslations] = useState(false);
  const { language } = useContext(MyContext);

  return (
    <div className="relative z-20 text-[1rem] md:text-[1.1rem]">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setOpenTranslations((prev) => !prev);
        }}
        className="flex space-x-2 mr-4 md:mr-0 text-black md:text-white font-bold md:hover:text-black"
      >
        {language === "Bosnian" ? (
          <>
            <span>{getUnicodeFlagIcon("BA")}</span>
            <span>BiH</span>
          </>
        ) : (
          <>
            <span>{getUnicodeFlagIcon("GB")}</span>
            <span>EN</span>
          </>
        )}
      </button>

      {openTranslations && (
        <LanguageModal setOpenTranslations={setOpenTranslations} />
      )}
    </div>
  );
};

export default LanguageSelector;
