import classNames from "classnames";
import React from "react";
import { useInView } from "react-intersection-observer";

const Footer = () => {
  return (
    <div className="flex items-center justify-center p-2 space-y-1 text-center bg-yellow-400 border-t border-black">
      <a
        href="https://farukspahic.com/"
        className="flex space-x-1 font-bold text-center text-[0.85rem] md:text-[0.9rem]"
      >
        <span> Developed by</span>
        <span className="underline">Faruk Spahic</span>
      </a>
    </div>
  );
};

export default Footer;
