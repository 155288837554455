import React from "react";
import { IoMdMail } from "react-icons/io";
import { BsFillTelephoneFill } from "react-icons/bs";
import LanguageSelector from "./LanguageSelector";

const ContactBar = () => {
  return (
    <div className="bg-yellow-400">
      <div className="flex items-center justify-between max-w-6xl mx-auto md:space-x-2">
        <div className="p-1 px-2 transform w-max flex md:space-x-4 md:bg-gray-900">
          <div className="flex items-center py-1 space-x-1">
            <IoMdMail className="md:text-yellow-400" />
            <a
              className="md:text-white font-bold underline"
              href="mailto:narucitetaxi@tuzlataxi.com"
            >
              narucitetaxi@tuzlataxi.com
            </a>
          </div>

          <div className="md:flex items-center mx-auto hidden space-x-1">
            <BsFillTelephoneFill className="md:text-yellow-400" />
            <a
              href="tel:+38762979333"
              className="md:text-white font-bold underline"
            >
              +387 62 979 333
            </a>
          </div>
        </div>

        <div className="hidden md:block">
          <LanguageSelector />
        </div>
      </div>
    </div>
  );
};

export default ContactBar;
