import Home from "./components/Home";
import GoToTopBtn from "./components/GoTopButton";
import { MyContextProvider } from "./context/context";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Pricing from "./components/Pricing";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";

function App() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: "head",
        nonce: undefined,
      }}
    >
      <MyContextProvider>
        <Router>
          <Helmet>
            <title>Tuzla Taxi 15-17</title>
            <meta
              name="description"
              content="Sigurno i udobno putujte s najboljom taksi službom u gradu."
            />
          </Helmet>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cjenovnik" element={<Pricing />} />
          </Routes>
        </Router>
        <GoToTopBtn />
      </MyContextProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
