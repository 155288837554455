import React, { useState } from "react";
import { PRIGRADSKE_VOZNJE } from "../locations";
import { useContext } from "react";
import { MyContext } from "../context/context";
import { MEDJUGRADSKE_VOZNJE } from "../locations";
import { DALJINSKE_VOZNJE } from "../locations";

const Calculator = () => {
  const {
    setPrice,
    languageData,
    destination,
    setDestination,
    setFinalDestination,
  } = useContext(MyContext);
  const [type, setType] = useState("");
  const [country, setCountry] = useState("");

  const findPrice = () => {
    if (type === "prigradske") {
      Object.entries(PRIGRADSKE_VOZNJE).map(([key, value]) =>
        key === destination ? setPrice(value) : ""
      );
    }

    if (type === "medjugradske") {
      Object.entries(MEDJUGRADSKE_VOZNJE).map(([key, value]) =>
        key === destination ? setPrice(value) : ""
      );
    }

    if (type === "daljinske" && country && DALJINSKE_VOZNJE[country]) {
      Object.entries(
        Object.entries(DALJINSKE_VOZNJE[country]).map(([key, value]) =>
          key === destination ? setPrice(value) : ""
        )
      );
    }

    setFinalDestination(destination);
  };

  return (
    <div className="flex justify-center px-5 pt-20 pb-5 text-white bg-gray-900 h-max">
      <div className="flex flex-col items-center justify-center space-y-10 w-max">
        <select
          onChange={(e) => setType(e.target.value)}
          className="w-full p-2 bg-gray-900 border focus:outline-none focus:border-yellow-500 placeholder:text-white max-w-[15rem]"
        >
          <option value="">{languageData?.pricingSelectOne}</option>

          <option value="prigradske" className="border">
            {languageData?.pricingSelectOptionOne}
          </option>
          <option value="medjugradske" className="">
            {languageData?.pricingSelectOptionTwo}
          </option>
          <option value="daljinske" className="">
            {languageData?.pricingSelectOptionThree}
          </option>
        </select>

        {type === "daljinske" && (
          <select
            onChange={(e) => {
              setCountry(e.target.value);
              setDestination("");
            }}
            className="w-full p-2 bg-gray-900 border focus:outline-none focus:border-yellow-500 placeholder:text-white max-w-[15rem]"
          >
            <option value="">{languageData?.pricingSelectTwo}</option>

            {Object.keys(DALJINSKE_VOZNJE).map((item) => (
              <option key={item}>{item}</option>
            ))}
          </select>
        )}

        <select
          onChange={(e) => setDestination(e.target.value)}
          className="w-full p-2 bg-gray-900 border focus:outline-none focus:border-yellow-500 placeholder:text-white max-w-[15rem]"
        >
          <option value="">{languageData?.pricingSelectThree}</option>

          {type === "prigradske" &&
            Object.keys(PRIGRADSKE_VOZNJE).map((item) => (
              <option key={item}>{item}</option>
            ))}

          {type === "medjugradske" &&
            Object.keys(MEDJUGRADSKE_VOZNJE).map((item) => (
              <option key={item}>{item}</option>
            ))}
          {type === "daljinske" &&
            country &&
            DALJINSKE_VOZNJE[country] &&
            Object.entries(DALJINSKE_VOZNJE[country]).map(([key, value]) => (
              <option key={key}>{key}</option>
            ))}
        </select>

        <button
          onClick={findPrice}
          className="px-7 py-2 rounded-full font-bold text-black duration-300 bg-yellow-400 border border-black hover:bg-gray-900 hover:border-yellow-400 hover:text-white"
        >
          Pretraga
        </button>
      </div>
    </div>
  );
};

export default Calculator;
